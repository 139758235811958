import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'selector', 'activate', 'reactivate', 'pause', 'renew' ]
  static classes = [ 'hide' ]
  static values = {
    showIf: String,
  }

  connect() {
    this.change()
  }

  change() {
    this.pauseTarget.classList.toggle('d-none', this.selectorTarget.value != 'pause')
    this.renewTarget.classList.toggle('d-none', this.selectorTarget.value != 'renew')
    this.activateTarget.classList.toggle('d-none', this.selectorTarget.value != 'activate')
    this.reactivateTarget.classList.toggle('d-none', this.selectorTarget.value != 'reactivate')
  }
}
