import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field", "output" ]
  static classes = [ "hide" ]
  static values = {
    showIf: String,
  }

  connect() {
    this.change()
  }

  change() {
    this.outputTarget.classList.toggle(this.hideClass, this.fieldTarget.value != this.showIfValue)

    this.outputTarget.querySelectorAll('input, select').forEach((item) => {
      item.disabled = this.fieldTarget.value != this.showIfValue
    })
  }
}
